import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
// import { Post } from "types";
import { useStaticQuery, graphql } from "gatsby";
import { COLOR, FONT } from "@src/global-theme";
import Work from "@components/Work";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BREAKPOINT } from "../global-theme";

const Landing = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
`;

const IntroGraf = styled.p`
  transition: all 0.1s;
  font: normal 18px/1.5 ${FONT.sansSerif};

  ${BREAKPOINT.md`
    font-size: 22px;
  `}
`;

const Introduction = styled.div`
  margin: 8rem 0;
  max-width: 800px;

  h2 {
    font: normal 58px/1.3 ${FONT.serif};
  }

  p {
    font: normal 22px/1.5 ${FONT.sansSerif};
  }
`;
const Home = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulProject {
        edges {
          node {
            title
            slug
            order
            company
            tools
            link
            description {
              description
            }
            thumbnail {
              file {
                url
                fileName
              }
            }
            images {
              file {
                url
                fileName
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allContentfulProject.edges;
  return (
    <Layout>
      <SEO title="Home" />
      <Landing>
        <Introduction>
          <h2>Hi, I'm Kayla </h2>
          <IntroGraf>
            I'm a frontend web developer with a focus on data vizualization
            currently based in Berlin, Germany. My website is being updated, so
            in the meantime please{" "}
            <a
              href="/kayla-robertson-resume-2020.pdf"
              aria-label="open resume"
              target="_blank"
            >
              view my CV
            </a>{" "}
            or{" "}
            <a
              href="mailto:kayla.robertson3@gmail.com"
              target="_blank"
              aria-label="send email"
            >
              email me
            </a>{" "}
            to chat or see my work.
          </IntroGraf>
        </Introduction>
        {/* <Work projects={projects} /> */}
      </Landing>
    </Layout>
  );
};
export default Home;
